import React, {useContext, useState} from 'react'
import {generatePath, useHistory} from "react-router-dom";

import teamImg from '../../../static/images/banner/team.png'

import {AppContext} from "../../../store/context";

import styles from './Banner.module.css'
import {publicRoutes} from "../../../routing";
import {CallbackForm} from "../../common/Form";

export const Banner = () => {
    const {context} = useContext(AppContext);
    const history = useHistory();

    return <div className={styles.container}>
        <div className={styles.imagesSection}>
            <div className={styles.image__main}>
                <div className={styles.overlayTop1}/>
                <div className={styles.overlayBot2}/>
                <img src={teamImg} alt={'team'} />
            </div>
            <p className={styles.image__subtitle}>Основатели компании: Евгений Ким Мадабай, Эльза Им, Евгений Ким, Инна Пак, Дмитрий Цой</p>
        </div>
        <div className={styles.textSection}>
            <div className={styles.preHeading}>한국 문화 레스토랑</div>
            <h1 className={styles.heading}>
                <span className={styles.heading__red}>10 лет </span>
                любви к корейской кухне
            </h1>
            <div className={styles.text}><span>Самый простой способ прикоснуться к другой культуре – ощутить ее национальный вкус</span>
            </div>
            <div className={styles.list}>
                <div className={styles.listItem} onClick={() => {
                    history.push(generatePath(publicRoutes.restaurants.path))
                }}>
                    <div className={styles.listItemBullet}>주소</div>
                    <div className={styles.listItemText}>РЕСТОРАНЫ</div>
                </div>
                <div className={styles.listItem}>
                    <div className={styles.listItemBullet}>식당</div>
                    <a href={"/menu"} target={"_blank"} className={styles.listItemText} rel="noopener noreferrer">
                        меню ресторана
                    </a>
                </div>
                <div className={styles.listItem}
                     onClick={() => context.modals.callBack.dispatch({type: "open"})}
                >
                    <div className={styles.listItemBullet}>연결</div>
                    <div className={styles.listItemText}>СВЯЗАТЬСЯ С НАМИ</div>
                </div>
                <div className={styles.listItem} onClick={() => {
                    history.push(generatePath(publicRoutes.delivery.path))
                }}>
                    <div className={styles.listItemBullet}>배달</div>
                    <div className={styles.listItemText}>Заказать доставку</div>
                </div>
                <div className={styles.listItem}
                     onClick={() => context.modals.restaurant.dispatch(
                         {type: "show", payload: context.common.state.restaurants[0]}
                     )}
                >
                    <div className={styles.listItemBullet}>예약</div>
                    <div className={styles.listItemText}>ЗАБРОНИРОВАТЬ СТОЛ</div>
                </div>
            </div>
        </div>
    </div>
}